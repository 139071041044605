.qualidade-container {
  width: 100%;
  height: var(--util-height);
  background-color: white;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.qualidade-img {
  width: 50%;
  height: 100%;
  overflow: hidden;
  display: flex;
}

.qualidade-img div:nth-child(1) {
  width: 100%;
  height: 100%;
}

.qualidade-txt {
  width: 50%;
  padding: 6rem;
  overflow: scroll;
}

.qualidade-txt p {
  margin-bottom: 10px;
  text-align: justify;
}

.qualidade-txt h2, h4 {
  margin-bottom: 10px;
}

.certificate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.certificate-download {
  display: flex;
  padding: 10px 30px;
  margin-right: 20px;
  background-color: var(--primary-color);
  border-radius: 5px;
  font-size: 1.7rem;
  text-align: center;  
}

.certificate-download:hover {
  background-color: #075434;
}

.certificate-download a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;

}

@media (max-width: 700px) {
  .qualidade-container {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }

  .qualidade-txt {
    padding: 4rem;
    width: 100%;
    height: 100%;
  }

  .qualidade-img {
    width: 100%;
    height: 100%;
  }
}