.contact-container {
  width: 100%;
  height: var(--util-height);
  background-color: white;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.contact-img {
  width: 50%;
  height: 100%;
  overflow: hidden;
  display: flex;
}

.contact-img div:nth-child(1) {
  width: 100%;
  height: 100%;
}

.contact-txt {
  width: 50%;
  padding: 6rem;
  overflow: scroll;
}

.contact-txt p {
  margin-bottom: 10px;
  text-align: justify;
}

.contact-txt h2, h4 {
  margin-bottom: 10px;
}

.contact-item {
  display: grid;
  grid-template-columns: 1fr 10fr;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-bottom: 10px;
}

.contact-map {
  width: auto;
  height: auto;
  padding: 5px;
}

.contact-map iframe {
  width: 100%;
  min-height: 220px;
  border: none;
  border-radius: 5px;
}

.contact-social {
  display: flex;
  align-items: center;
  justify-content: left;
}

.contact-social a {
  text-decoration: none;
  color: var(--secondary-color);
  padding: 0 10px;
}

.contact-info a {
  text-decoration: none;
  color: var(--secondary-color);
}

@media (max-width: 700px) {
  .contact-container {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }

  .contact-txt {
    padding: 4rem;
    width: 100%;
    height: 100%;
  }

  .contact-img {
    width: 100%;
    height: 100%;
  }
}