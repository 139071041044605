.nf-banner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - var(--menu-height) - var(--footer-height));
  align-items: center;
  justify-content: center;
  text-align: center;
}

.nf-banner h2 {
  color: var(--primary-color);
  margin-bottom: 5rem;
}

.nf-banner-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nf-banner-img img {
  width: 60%;
}
