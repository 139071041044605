body {
  margin: 0;
  padding: 0;
  color: var(--font-color);
}

/* 
navbar 
 |_ logo
 |_ menu-icon
 |_ menu-container
    |_ menu-list
        |_ menu-item
            |_ menu-item-link
            |_ submenu-list
                |_ submenu-item ]
*/

.navbar {
  width: 100%;
  height: var(--menu-height);
  display: flex;
  position: fixed;
  margin: 0 auto;
  padding: 0 3rem;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  background-color: var(--menu-color);
  box-shadow: 1px 1px 10px 1px var(--secondary-color);
}

.nav-padding {
  width: 100%;
  height: var(--menu-height);
}

.logo img {
 width: 40px;
 height: auto;
}

.menu-icon {
  display: none;
}
.menu-icon img {
  width: 40px;
  height: 40px;
}

.menu-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
}

.menu-item {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
}

.menu-item:hover .submenu-list {
  display: block;
}

.menu-item-link {
  font-size: 2rem;
  font-weight: 400;
  color: var(--font-color);
  text-decoration: none;
  text-align: center;
  display: block;
  padding: 1rem;
  color: var(--secondary-color);
  position: relative;
}

.menu-item:not(:last-child):not(:last-child) {
  margin-right: 0px;
}

.menu-item-link.active {
  color: var(--primary-color);
  font-weight: 500;
  position: relative;
}

.menu-item-link::after {
  content: '';
  position: absolute;
  bottom: 0.6rem;
  left: 50%;
  width: 0;
  height: 0.2rem;
  background: var(--primary-color);
  transition: all 300ms ease-in-out;
}

.menu-item-link:hover::after {
  width: 50%;
  left: 25%;
}

.submenu-list {
  position: absolute;
  top: calc(var(--menu-height) - 10px);
  left: 0;
  width: 100%;
  height: auto;
  padding: 10px;
  list-style: none;
  background-color: white;
  border-radius: 2px;
  font-size: 2rem;
  flex-direction: column;
  z-index: 9999;
  box-shadow: 1px 1px 8px 1px var(--secondary-color);
  display: none;
}

.submenu-item {
  padding: 5px;
}

.submenu-item a {
  font-size: 1.5rem;
  text-align: left;
  text-decoration: none;
  font-weight: 400;
  color: var(--font-color);
}

@media (max-width: 700px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .menu-container {
    position: absolute;
    width: 0px;
    height: calc(100vh - var(--menu-height));
    top: var(--menu-height);
    right: 0;
    background-color: var(--menu-color);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .menu-container.active {
    width: var(--lateral-menu);
  }

  .menu-list {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .menu-item {
    padding: 5px 20px;
  }

  .submenu-list {
    top: 45px;
    width: var(--lateral-menu);
  }
}