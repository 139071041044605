
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;700;900&display=swap');


:root {
  --menu-height: 5rem;
  --lateral-menu: 270px;
  --footer-height: 3rem;
  --util-height: calc(100vh - var(--menu-height) - var(--footer-height));
  --primary-color: #088551;
  --secondary-color: #6C6C6F;
  --menu-color: white;
  --font-color: grey;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: 'League Spartan', sans-serif;
  font-weight: 300;
  font-size: 2rem;
  color: var(--font-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2 {
  color: var(--primary-color);
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #a3a3a3; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7c7c7c; 
}