.qs-container {
  width: 100%;
  height: var(--util-height);
  background-color: white;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.qs-img {
  width: 50%;
  height: 100%;
  overflow: hidden;
  display: flex;
}

.qs-img img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.qs-img div:nth-child(1) {
  width: 100%;
  height: 100%;
}

.qs-txt {
  width: 50%;
  padding: 6rem;
  overflow: scroll;
}

.qs-txt p {
  margin-bottom: 10px;
  text-align: justify;
}

.qs-txt h2, h4 {
  margin-bottom: 10px;
}

.qs-txt ul {
  margin-bottom: 10px;
  margin-left: 40px;
  text-align: justify;
}

@media (max-width: 700px) {
  .qs-container {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }

  .qs-txt {
    padding: 4rem;
    width: 100%;
    height: 100%;
  }

  .qs-img {
    width: 100%;
    height: 100%;
  }
}