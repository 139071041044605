.service-container {
  width: 100%;
  height: var(--util-height);
  background-color: white;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.service-img {
  width: 50%;
  height: 100%;
  overflow: hidden;
  display: flex;
}

.service-img img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.service-txt {
  width: 50%;
  padding: 6rem;
  overflow: scroll;
}

.service-txt p {
  margin-bottom: 10px;
  text-align: justify;
}

.service-txt h2, h4 {
  margin-bottom: 10px;
}

@media (max-width: 700px) {
  .service-container {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }

  .service-txt {
    padding: 4rem;
    width: 100%;
    height: 100%;
  }

  .service-img {
    width: 100%;
    height: 100%;
  }
}