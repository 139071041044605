.banner {
  width: 100%;
  height: var(--util-height);
  position: relative;
  background-image: url('../../assets/images/background.webp');
  background-size: cover;
  background-position: center;
}

.banner-img {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow:hidden;
}

.banner-img img {
  width: 100%;
}

.text-over-left {
  position: absolute;
  top: 7rem;
  left: 4rem;
  font-weight: 600;
  font-size: 3rem;
  text-shadow: 2px 2px 2px rgb(79, 78, 78);
}

.text-over-left h1 {
  color: white;
}

.text-over-left h2 {
  font-weight: 400;
  font-size: 3rem;
  color: white;
}

.button {
  width: 300px;
  height: 40px;
  background-color: var(--primary-color);
  border: none;
  border-radius: 10px;
  font-family: 'League Spartan', sans-serif;
  font-size: 1.7rem;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.button:hover{
  background-color: #055131;;
}

@media (max-width: 1000px) {
  .text-over-left {
    top: 4.5rem;
    left: 4.5rem;
    font-size: 2.5rem;
  }

  .text-over-left h2 {
    font-size: 2.5rem;
  }

  .banner-img img {
    height: 100%;
    width: auto;
  }
}

@media (max-width: 700px) {
  .text-over-left {
    top: 7rem;
    left: 3rem;
    font-size: 2rem;
  }

  .text-over-left h2 {
    font-size: 2rem;
  }

  .banner {
    background-image: url('../../assets/images/background-small.webp');
  }  

  .banner-img img {
    height: 100%;
    width: auto;
  }
}